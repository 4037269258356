<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="d-flex">
        <div class="mr20">
          <el-select class="mr20" style="width: 240px;" v-model="formDetailType" clearable placeholder="请选择">
            <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker clearable
                  v-model="createTime"
                  type="daterange"  style="width: 390px"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" @click="searchPage">查询</el-button>
      </div>
      <div class="ml-auto d-flex align-cen" >
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>
    </div>

    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
        <el-table-column align="center"
                         style="-webkit-align-items: center;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;-webkit-box-orient: vertical;"
                         prop="name" label="用户名"></el-table-column>
        <el-table-column align="center" prop="commitTime" width="170" label="提交时间"></el-table-column>
        <div v-for="(item) in options" :key="item.id">
          <el-table-column align="center" v-if="item.id == ('SINGLE' + (item.detailId))" :label="item.name">
            <template slot-scope="scope">
              <span> {{ scope.row['single' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == ('MULTIPLE' + (item.detailId)) "  prop="multiple" :label="item.name">
            <template slot-scope="scope">
              <span> {{ scope.row['multiple' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DROP_DOWN'  + (item.detailId) " :label="item.name">
            <template slot-scope="scope">
              <span> {{ scope.row['dropDown' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'TEXTAREA' + (item.detailId)" :label="item.name" width="200px">
            <template slot-scope="scope">
              <span> {{ scope.row['textarea' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'SCOURE' + (item.detailId) "  :label="item.name">
            <template slot-scope="scope">
              <span> {{ scope.row['scoure' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'IMAGE'+ (item.detailId) " prop="image" :label="item.name">
            <template slot-scope="scope">
              <el-image v-if="scope.row['image'+(item.detailId)].length > 0"
                        style="width: 36px;height:36px"
                        :src="scope.row['image'+(item.detailId)][0]"
                        :preview-src-list="scope.row['image'+(item.detailId)]">
              </el-image>
              <span v-if="scope.row['image'+(item.detailId)].length > 0">x{{scope.row['image' + (item.detailId)].length }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'GPS' + (item.detailId)" prop="gps" :label="item.name"></el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'USERINFO' + (item.detailId) " :label="item.name">
            <template slot-scope="scope">
              <!--              <div v-for="(info,index) in scope.row.userInfo" :key="index">-->
              <span>{{ scope.row['userInfo' + (item.detailId)] }}</span>
              <!--              </div>-->
            </template>
          </el-table-column>
          <el-table-column align="center" v-else-if="item.id == 'DATEPICKER' + (item.detailId) "  prop="datepicker" :label="item.name">
            <template slot-scope="scope">
              <span>{{ scope.row['datepicker' + (item.detailId)] }}</span>
            </template>
          </el-table-column>
        </div>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryUserCommitDetail,
  queryAdvancedFormById,

} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import console from "console";
export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      value: "",
      tableData: [],
      optionList:'', //全部分类
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      options:[
        {id:'',name:"全部"}
      ],
      createTime:'',
      formDetailType:'',
      start:'',
      end:'',
      advancedFormId:'',
      tableDataALL:[],
      exportTableTh: [],
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  created(){
    //检查是否编辑
    let advancedFormId = this.$route.query.id;

    if (advancedFormId) {
      this.advancedFormId = advancedFormId
      this.getDetail()
    }
  },
  watch:{
    createTime(val,old){
      this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
    }
  },
  methods:{
    getDetail(){
      queryAdvancedFormById({
        advancedFormId:this.advancedFormId
      }).then(res => {
        if(res.code == 200){
          this.form = res.data;
          this.exportTableTh = res.data.detailRequests;
          this.form.detailRequests.forEach((item,index) =>{
            this.options.push( {id:item.formType+item.detailId,name:item.title,detailId:item.detailId});
          });
          console.log(this.options)
          this.getTableData();
          this.getTableDataALL();
        }else{
          this.exportTableTh = [];
          this.options = [];
        }
      });
    },
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        advancedFormId:this.advancedFormId,
        startTime:this.start || '',
        endTime:this.end || '',
      };
      if ( this.formDetailType ) {
        for ( let i in this.options) {
          if (  this.options[i].id == this.formDetailType) {
            data.title = this.options[i].name
          }
        }
      }
      queryUserCommitDetail(data)
        .then((res) => {
          if (res && res.data.pageInfo.list[0] != null) {
            this.loading = false;
            this.tableData = res.data.pageInfo.list;
            this.total = res.data.pageInfo.total;
            this.tableData.forEach(item => {
              if (item.datepicker && item.datepicker != "") {
                const datepickers = item.datepicker.split("=");
                for (let j in datepickers) {
                  let datepickerItem = JSON.parse(datepickers[j]);
                  let de = String('datepicker' + datepickerItem[0].detailIdFatherId)
                  item[de] = datepickerItem[0].answer
                }
              }
              if (item.userInfo && item.userInfo != "") {
                const userInfos = item.userInfo.split("=");
                for (let j in userInfos) {
                  let userInfoItem = JSON.parse(userInfos[j]);
                  let de = String('userInfo' + userInfoItem[0].detailIdFatherId)
                  item[de] = userInfoItem[0].answer
                }
              }

              if (item.image && item.image != "") {
                const images = item.image.split("=");
                for (let j in images) {
                  let imageItem = JSON.parse(images[j]);
                  let de = String('image' + imageItem[0].detailIdFatherId)
                  item[de] = imageItem[0].answer
                }
              }

              if (item.scoure && item.scoure != "") {
                const scoures = item.scoure.split("=");
                for (let j in scoures) {
                  let a = []
                  let scoureItem = JSON.parse(scoures[j]);
                  scoureItem.map((sc) => {
                    let de = String('scoure' + sc.detailIdFatherId)
                    a.push(parseInt(sc.answer) + 1 + '星')
                    item[de] = a.join(',')
                  })
                }
              }

              if (item.textarea && item.textarea != "") {
                const textareas = item.textarea.split("=");
                for (let j in textareas) {
                  let textareaItem = JSON.parse(textareas[j]);
                  let de = String('textarea' + textareaItem[0].detailIdFatherId)
                  console.log(de)
                  item[de] = textareaItem[0].answer
                }
              }

              if (item.dropDown && item.dropDown != "") {
                const dropDowns = item.dropDown.split("=");
                for (let j in dropDowns) {
                  let dropDownItem = JSON.parse(dropDowns[j]);
                  let de = String('dropDown' + dropDownItem[0].detailIdFatherId)
                  item[de] = dropDownItem[0].answer
                }
              }

              if (item.single && item.single != "") {
                try {
                  const singTemp = item.single.split("=");
                  for (let i in singTemp) {
                    let singTempItem = JSON.parse(singTemp[i]);
                    singTempItem.map((sc) => {
                      if (sc.answer == 'true' || sc.answer == true) {
                        let de = String('single' + sc.detailIdFatherId)
                        item[de] = sc.formDetailtitle
                      }
                    })
                  }
                  //   const single = JSON.parse(singTemp[0]);
                  //   const result = single.find(function(sc){ return sc.answer == true });
                  //   item.single1 = result ? result.formDetailtitle : '';
                  // console.log(item.single1)
                  //   const single2 = JSON.parse(singTemp[1]);
                  //   const result2 = single2.find(function(sc){ return sc.answer == true });
                  //   item.single2 = result2 ? result2.formDetailtitle : '';
                } catch (error) {
                  console.log(error)
                }
              } else {
                item.single = "";
              }

              if(item.multiple && item.multiple != ""){
                const multiples = item.multiple.split("=");
                for (let j in multiples) {
                  let a= []
                  let multipleItem = JSON.parse(multiples[j]);
                  multipleItem.map((sc) => {
                    if (sc.answer == 'true' || sc.answer == true) {
                      let de = String('multiple' + sc.detailIdFatherId)
                      a.push(sc.formDetailtitle)
                      item[de] = a.join(',')
                    }
                  })
                }


                // const multiple = JSON.parse(item.multiple);
                // item.multiple = multiple.map(function(sc){return sc.formDetailtitle}).join("/");
                // item.multiple = multiple.filter(v => v.answer).map(v => v.formDetailtitle).join("/");
              }else{
                item.multiple = "";
              }
            })


            console.log(this.tableData)
          }else{
            this.tableData = [];
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    getTableDataALL() {
      this.loading = true;
      let data = {
        pageNum: 1,
        pageSize: 9999,
        advancedFormId:this.advancedFormId,
        // startTime:this.start || '',
        // endTime:this.end || '',
        // formDetailType:this.formDetailType || ''
      };

      queryUserCommitDetail(data)
          .then((res) => {
            if (res && res.data.pageInfo.list[0] != null) {
              this.loading = false;
              this.tableDataALL = res.data.pageInfo.list;
              this.total = res.data.pageInfo.total;
              this.tableDataALL.forEach((item, i) => {
                item.index = Number(i) + 1;
                if (item.datepicker && item.datepicker != "") {
                  const datepickers = item.datepicker.split("=");
                  for (let j in datepickers) {
                    let datepickerItem = JSON.parse(datepickers[j]);
                    let de = String('datepicker' + datepickerItem[0].detailIdFatherId)
                    item[de] = datepickerItem[0].answer
                  }
                }
                if (item.userInfo && item.userInfo != "") {
                  const userInfos = item.userInfo.split("=");
                  for (let j in userInfos) {
                    let userInfoItem = JSON.parse(userInfos[j]);
                    let de = String('userInfo' + userInfoItem[0].detailIdFatherId)
                    item[de] = userInfoItem[0].answer
                  }
                }

                if (item.image && item.image != "") {
                  const images = item.image.split("=");
                  for (let j in images) {
                    let imageItem = JSON.parse(images[j]);
                    let de = String('image' + imageItem[0].detailIdFatherId)
                    item[de] = imageItem[0].answer.join(',')
                  }
                }

                if (item.scoure && item.scoure != "") {
                  const scoures = item.scoure.split("=");
                  for (let j in scoures) {
                    let a = []
                    let scoureItem = JSON.parse(scoures[j]);
                    scoureItem.map((sc) => {
                      let de = String('scoure' + sc.detailIdFatherId)
                      a.push(parseInt(sc.answer) + 1 + '星')
                      item[de] = a.join(',')
                    })
                  }
                }

                if (item.textarea && item.textarea != "") {
                  const textareas = item.textarea.split("=");
                  for (let j in textareas) {
                    let textareaItem = JSON.parse(textareas[j]);
                    let de = String('textarea' + textareaItem[0].detailIdFatherId)
                    item[de] = textareaItem[0].answer
                  }
                }

                if (item.dropDown && item.dropDown != "") {
                  const dropDowns = item.dropDown.split("=");
                  for (let j in dropDowns) {
                    let dropDownItem = JSON.parse(dropDowns[j]);
                    let de = String('dropDown' + dropDownItem[0].detailIdFatherId)
                    item[de] = dropDownItem[0].answer
                  }
                }

                if (item.single && item.single != "") {
                  try {
                    const singTemp = item.single.split("=");
                    for (let i in singTemp) {
                      let singTempItem = JSON.parse(singTemp[i]);
                      singTempItem.map((sc) => {
                        if (sc.answer == 'true' || sc.answer == true) {
                          let de = String('single' + sc.detailIdFatherId)
                          item[de] = sc.formDetailtitle
                        }
                      })
                    }
                  } catch (error) {
                    console.log(error)
                  }
                } else {
                  item.single = "";
                }

                if(item.multiple && item.multiple != ""){
                  const multiples = item.multiple.split("=");
                  for (let j in multiples) {
                    let a= []
                    let multipleItem = JSON.parse(multiples[j]);
                    multipleItem.map((sc) => {
                      if (sc.answer == 'true' || sc.answer == true) {
                        let de = String('multiple' + sc.detailIdFatherId)
                        a.push(sc.formDetailtitle)
                        item[de] = a.join(',')
                      }
                    })
                  }
                }else{
                  item.multiple = "";
                }
              })
            } else {
              this.tableDataALL = [];
              this.totalCount = 0;
            }
          })
        .catch((err) => {
          this.loading = false;
          this.tableDataALL = [];
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.loading = true;
      this.getTableData();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    searchPage(){
      if (this.createTime) {
        this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
        this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
      }else {
        this.start =  ''
        this.end = ''
      }
      this.getTableData();
    },
    exportData: function () {
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/vendor/Export2Excel');

        let tHeaderData = [];
        if(this.exportTableTh.length){
          let oTit = this.exportTableTh.map(v => v.title);
          tHeaderData.push(...oTit);
          tHeaderData.unshift(...['序号','用户名']);
          tHeaderData.push('提交时间');
        }else{
          tHeaderData = ['序号','用户名','提交时间'];
        };
        const tHeader = tHeaderData;

        let filterValData = [];
        if(this.exportTableTh.length){
          let vals = this.exportTableTh.map(v => {
            if (v.formType == 'SINGLE') {
              v.formType = 'single' + v.detailId
            };
            if (v.formType == 'TEXTAREA') {
              v.formType = 'textarea' + v.detailId
            };
            if (v.formType == 'DROP_DOWN') {
              v.formType = 'dropDown' + v.detailId
            };
            if (v.formType == 'IMAGE') {
              v.formType = 'image' + v.detailId
            };
            if (v.formType == 'MULTIPLE') {
              v.formType = 'multiple' + v.detailId
            };
            if (v.formType == 'DATEPICKER') {
              v.formType = 'datepicker' + v.detailId
            };
            if (v.formType == 'SCOURE') {
              v.formType = 'scoure' + v.detailId
            };
            if (v.formType == 'USERINFO') {
              v.formType = 'userInfo' + v.detailId
            };
            if (v.formType == 'GPS') {
              v.formType = 'gps'
            };
            return v.formType
          });
          filterValData = vals;
          filterValData.unshift(...['index','name',]);
          filterValData.push('commitTime');
        }else{
          filterValData = ['index','name','commitTime'];
        };
        const filterVal = filterValData;
        const list = this.tableDataALL;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '反馈详情');
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
